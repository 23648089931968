import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsQuestionnaireActive,
  selectQuestionnaireCompletedAt,
} from '@/store/slices/questionnaire';
import {
  selectIsOpenCaseClosureTimeDialog,
  setHasDismissedCaseClosureTimeDialog,
  setIsCaseClosureTimeDialogOpen,
  setIsConfirmingReopenCase,
} from '@/store/slices/ui';
import updateCaseClosureTime from '@/store/thunks/updateCaseClosureTime';
import ConfirmationModal from '../ConfirmationModal';
import { isNullishTimestamp as isNullishTime } from '@/utils/helpers';
import { isValid } from 'date-fns';
import { locales } from '../Question/questionControls/DatePicker/locales';
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { slotProps } from './slotProps';
import { Alert, AlertTitle, Typography } from '@mui/material';

export const CaseClosureDateTimeDialog = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const completedAt = useAppSelector(selectQuestionnaireCompletedAt);
  const isDialogOpen = useAppSelector(selectIsOpenCaseClosureTimeDialog);
  const isActiveQuestionnaire = useAppSelector(selectIsQuestionnaireActive);
  const [dateTime, setDateTime] = useState<Date | null>(
    !isNullishTime(completedAt!) ? new Date(completedAt!) : new Date()
  );
  const userLocale = navigator.languages[0] || navigator.language;

  const handleOnChangeDate = (date: Date | null): void => {
    if (!isValid(date)) {
      setDateTime(new Date());
      return;
    }
    setDateTime(date);
  };

  const handleOnClickCancel = (): void => {
    dispatch(setIsCaseClosureTimeDialogOpen(false));
    dispatch(setHasDismissedCaseClosureTimeDialog(true));
  };

  const handleOnClickApplyOrDismiss = (): void => {
    if (isValid(dateTime) && !isActiveQuestionnaire) {
      dispatch(updateCaseClosureTime(dateTime?.toISOString()!, false));
    }
    dispatch(setIsCaseClosureTimeDialogOpen(false));
  };

  const handleOnClear = (): void => {
    dispatch(setIsCaseClosureTimeDialogOpen(false));
    dispatch(setIsConfirmingReopenCase(true));
  };

  useEffect(() => {
    !isDialogOpen &&
      setDateTime(
        !isNullishTime(completedAt!) ? new Date(completedAt!) : new Date()
      );
  }, [completedAt, isDialogOpen]);

  return (
    <ConfirmationModal
      isOpen={isDialogOpen}
      title={'EDIT CASE CLOSURE TIME'}
      onClose={handleOnClickCancel}
      onConfirm={handleOnClickApplyOrDismiss}
      showClearButton={!isActiveQuestionnaire}
      hideCancelButton={true}
      onClear={handleOnClear}
      confirmText={isActiveQuestionnaire ? 'Dismiss' : 'Apply'}
    >
      {isActiveQuestionnaire ? (
        <>
          <Typography gutterBottom>
            <b>The current case is still open.</b>
          </Typography>
          <Alert severity='info'>
            <AlertTitle>Note:</AlertTitle>
            Please <b>close the case</b> before editing the closure time.
          </Alert>
        </>
      ) : (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={
            locales[userLocale?.toLowerCase() as keyof typeof locales] ||
            locales['en-gb']
          }
        >
          <DateTimePicker
            sx={{ width: '100%' }}
            value={dateTime}
            ampm={true}
            onChange={handleOnChangeDate}
            slotProps={slotProps}
            views={['year', 'month', 'day', 'hours', 'minutes']}
            closeOnSelect={false}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
          />
        </LocalizationProvider>
      )}
    </ConfirmationModal>
  );
};
