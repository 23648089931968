import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { selectQuestionnaireStartedAt } from '@/store/slices/questionnaire';
import {
  selectHasDismissedQuestionnaireTimeDialog,
  selectIsOpenQuestionnaireTimeDialog,
  setHasDismissedQuestionnaireTimeDialog,
  setIsQuestionnaireTimeDialogOpen,
} from '@/store/slices/ui';
import { useRouter } from 'next/router';
import updateQuestionnaireTime from '@/store/thunks/updateQuestionnaireTimeThunk';
import ConfirmationModal from '../ConfirmationModal';
import { slotProps } from './slotProps';
import { isNullishTimestamp as isNullishTime } from '@/utils/helpers';
import { isValid } from 'date-fns';
import { locales } from '../Question/questionControls/DatePicker/locales';
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';

export const QuestionnaireDateTimeDialog = (): JSX.Element => {
  const router = useRouter();
  const isEditorRoute = router.route.includes('/edit');
  const dispatch = useAppDispatch();
  const startedAtTime = useAppSelector(selectQuestionnaireStartedAt);
  const isDialogOpen = useAppSelector(selectIsOpenQuestionnaireTimeDialog);
  const hasDismissedDialog = useAppSelector(
    selectHasDismissedQuestionnaireTimeDialog
  );
  const [dateTime, setDateTime] = useState<Date | null>(
    !isNullishTime(startedAtTime!) ? new Date(startedAtTime!) : new Date()
  );
  const userLocale = navigator.languages[0] || navigator.language;
  const shouldShowDialog =
    !isEditorRoute && !hasDismissedDialog && isNullishTime(startedAtTime!);

  const handleOnChangeDate = (date: Date | null): void => {
    if (!isValid(date)) {
      setDateTime(new Date());
      return;
    }
    setDateTime(date);
  };

  const handleOnClickCancel = (): void => {
    dispatch(setIsQuestionnaireTimeDialogOpen(false));
    dispatch(setHasDismissedQuestionnaireTimeDialog(true));
  };

  const handleOnClickApply = (): void => {
    if (isValid(dateTime)) {
      dispatch(updateQuestionnaireTime(dateTime?.toISOString() as string));
    } else {
      dispatch(updateQuestionnaireTime(null));
      dispatch(setHasDismissedQuestionnaireTimeDialog(true));
    }
    dispatch(setIsQuestionnaireTimeDialogOpen(false));
  };

  const handleOnClear = (): void => setDateTime(null);

  useEffect(() => {
    shouldShowDialog && dispatch(setIsQuestionnaireTimeDialogOpen(true));
  }, [shouldShowDialog, dispatch]);

  return (
    <ConfirmationModal
      isOpen={isDialogOpen}
      title={'EDIT INTAKE TIME'}
      onClose={handleOnClickCancel}
      onConfirm={handleOnClickApply}
      sxStyles={{ bottom: '20vh' }}
      showClearButton={true}
      hideCancelButton={true}
      onClear={handleOnClear}
      confirmText='Apply'
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={
          locales[userLocale?.toLowerCase() as keyof typeof locales] ||
          locales['en-gb']
        }
      >
        <DateTimePicker
          sx={{ width: '100%' }}
          value={dateTime}
          ampm={true}
          onChange={handleOnChangeDate}
          //@ts-ignore
          slotProps={{
            ...slotProps,
          }}
          views={['year', 'month', 'day', 'hours', 'minutes']}
          closeOnSelect={false}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
        />
      </LocalizationProvider>
    </ConfirmationModal>
  );
};
